import { Injectable } from '@angular/core';
import { SafetyDataAPI } from './safety-data-api.service';
import { IDeleteAccount } from '../classes/_interfaces';
import { AuthentService } from './authent.service';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class DeleteAccountService {

    constructor(
        private safetyDataAPI: SafetyDataAPI,
        private storageService: StorageService,
        private authService: AuthentService,
    ){

    }

    public deleteAccount(deleteAccount: IDeleteAccount): Promise<any> {
        return this.safetyDataAPI.deleteUserAccount(deleteAccount);
    }

    public async clearLocalStorage() {
        this.storageService.clear();
        await this.authService.logout();
    }

}
