import { Component, Input, ViewChild } from "@angular/core";
import { IonContent, ModalController } from "@ionic/angular";
import { FavouritesService } from "src/app/services/favourites.service";
import { LoadingProvider } from 'src/app/services/loading/loading';
import { DialogService } from "src/app/services/dialog.service";
import { EditListAlertPopup } from "../EditListAlertPopup/EditListAlertPopup";
import { DeleteAlertPopup } from "../DeleteAlertPopup/DeleteAlertPopup";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-popover",
  templateUrl: "popover.component.html",
  styleUrls: ['popover.component.scss']

})
export class PopoverComponent {
  @Input() oldName: string;
  @Input() onDismiss: (v?:boolean) => void;
  @Input() btnList: {
    showDelete: boolean,
    showEdit: boolean,
    showArchived: boolean
  } = {
    showArchived: false,
    showDelete: true,
    showEdit: true
  }
  private scrollPosition: number;
  @ViewChild(IonContent) content: IonContent;
  favValue: any;
  favSelectedVal: any;
  favSelectedValue: any;
  popover: any;
  public isDesktop:boolean;

  constructor(private favoritesService: FavouritesService,
    private loading: LoadingProvider,
    private dialogService: DialogService,
    private modalCtrl: ModalController,
    private deviceService: DeviceDetectorService,
    ) {
      this.isDesktop = this.deviceService.deviceType !== 'tablet' && (this.deviceService.os === 'Mac' || this.deviceService.os === 'Windows');
  }

  // onClickItem(item: string) {
  //   if(item == "Edit"){
  //     this.showEditModal();
  //   }
  //   else{
  //     this.deleteFavorite();
  //   }
  // }

  onClickEdit(){
    this.showEditModal();
  }

  onClickDelete(){
   
    this.showDeleteModal();

  }
  onClose(){
    this.onDismiss();
  }

  async showEditModal() {
    try {
     this.favSelectedVal = this.favValue.name;
    
      this.loading.start();
      //await this.favoritesService.showListUpdateForm(favSelectedVal);
      await this.editListPopup(this.favSelectedVal);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      console.log("error-->"+error)
      this.loading.stop();
    }
  }

  public async editListPopup(favSelectedValue){
    this.popover.dismiss();
    const modal = await this.modalCtrl.create({
      component: EditListAlertPopup,
      cssClass  : 'edit-list-popup-modal',
      componentProps: {
        favSelectedValue:this.favSelectedValue,
        oldName:this.oldName,
        onDismiss: this.onDismiss,
        onSearchClear: () => {
          this.popover.dismiss();
        },
      }
      
    });
    return await modal.present();

  }

  async showDeleteModal() {
    try {
     this.favSelectedVal = this.favValue.name;
      const pgName = "popover"
      this.loading.start();
       await this.deleteFavorite(pgName);
      this.retainScrollPosition();
      this.loading.stop();
    } catch (error) {
      console.log("error-->"+error)
      this.loading.stop();
    }
  }

  public async deleteFavorite(pgName){
    this.popover.dismiss();
    const modal = await this.modalCtrl.create({
      component: DeleteAlertPopup,
      cssClass  : 'delete-favorite-popup-modal',
      componentProps: {
        favSelectedValue:this.favSelectedValue,
        oldName:this.oldName,
        pgName: pgName,
        onDismiss: this.onDismiss,
        onSearchClear: () => {
          this.popover.dismiss();
        },
      }
      
    });
    return await modal.present();

  }
  public async onClickArchived(){
    this.onDismiss(true);
  }  

  // async deleteFavorite(value) {
  //   const favData = value;
  //   this.popover.dismiss();
  //   if (await this.dialogService.confirm('FAVORITES_DELETE_CONFIRM_TITLE', 'FAVORITES_LIST_DELETE_SELECTED_CONFIRM_MESSAGE')) {
  //     try {
  //       this.loading.start();
  //       await this.favoritesService.removeFavoritesList(favData.name);
  //       this.retainScrollPosition();
  //       this.dialogService.notify('LIST_DELETED_SUCCESS');
  //       this.loading.stop();
  //       this.onDismiss()
  //       // window.location.reload();
  //     } catch (error) {
  //       this.loading.stop();
  //     }
  //   }
  // }

  private retainScrollPosition() {
    setTimeout(() => {
      this.content.scrollToPoint(0, this.scrollPosition);
    }, 0);
  }
}
