import { Injectable } from '@angular/core';
import { Article } from '../../types/types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UtilProvider {


  constructor(private translate: TranslateService) {
  }

  public isEmpty(object: any): boolean {
    let isEmpty = true;

    if (!object) {
      return true;
    }

    Object.keys(object).forEach(key => {
      if (typeof object[key] === 'string') {
        if (this.isStringNotEmpty(object[key])) {
          isEmpty = false;
        }
      } else {
        if (Array.isArray(object[key])) {
          if (object[key].length > 0) {
            if (typeof object[key][0] === 'string') {
              object[key].forEach(item => {
                if (this.isStringNotEmpty(item)) {
                  isEmpty = false;
                }
              })
            } else {
              object[key].forEach(item => {
                isEmpty = isEmpty && this.isEmpty(item);
              })
            }
          }
        } else {
          isEmpty = isEmpty && this.isEmpty(object[key])
        }
      }
    })
    return isEmpty
  }

  isStringNotEmpty(stringObject: string): boolean {
    return stringObject && stringObject.length > 0
  }

  getArticleCasMetadata(article: Article): string {
    const { articleNumber, casNumber } = article;
    let articleMetadata = '';
    let casMetadata = '';
    if (articleNumber) {
      articleMetadata = `${this.translate.instant('SEARCH_ITEM_ARTICLE')}: ${articleNumber}`;
    }
    if (casNumber) {
      casMetadata = `${this.translate.instant('SEARCH_ITEM_CAS')}: ${casNumber}`;
    }
    return articleMetadata && casMetadata ? `${articleMetadata}, ${casMetadata}` : `${articleMetadata}${casMetadata}`;
  }

  /**
   * Obtain GETs parameter and return them
   * as an object.
   *
   * @param url url with GET parameters
   */
  public obtainParametersFromURL(url: string){
    if (url.split('?')[1]) {
      let parametersText = url.split('?')[1];
      let parameters = parametersText.split('&');
      let response = {}

      parameters.forEach(parameter => {
        let separation = parameter.split('=');
        let key = separation[0];
        let value = separation[1];
        response[key] = value;
      });
      return response;
    } else {
      return {};
    }
  }

  public obtainParametersFromAppleURL(url: string){
    if (url.split('#')[1]) {
      let parametersText = url.split('#')[1];
      let parameters = parametersText.split('&');
      let response = {}

      parameters.forEach(parameter => {
        let separation = parameter.split('=');
        let key = separation[0];
        let value = separation[1];
        response[key] = value;
      });
      return response;

    } else {
      return {};
    }
  }
}
