import { Injectable } from '@angular/core';
import { AlertController, ToastController,ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { storageKeys } from '../services/storage-keys';
import { Storage } from '@ionic/storage';
import {DisclaimerModal} from '../components/diclaimer-modal/disclaimer-modal';

@Injectable({
    providedIn: 'root',
})
export class DialogService {

    private currentToast;

    constructor(private alertCtrl: AlertController,
        private translate: TranslateService,
        private modalCtrl: ModalController,
        private storage: Storage,
        private toastCtrl: ToastController) {
        this.toastCtrl.create({ animated: false }).then(t => { t.present(); t.dismiss(); });
    }

    public getFullStatusError(status: any, tryAgain: boolean, thankYou: boolean): string {

        let breaker = '';

        if (!status) status = '500';

        let error: string = this.translate.instant('ERROR_' + Number(status));

        if (tryAgain)
            error += breaker + this.translate.instant('ERROR_TRY_AGAIN');

        if (thankYou)
            error += breaker + this.translate.instant('ERROR_THANK_YOU');

        return error;
    }

    public critical(title: string, message: string, subTitle?: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let alert = await this.alertCtrl.create({
                header: this.translate.instant(title),
                message: this.translate.instant(message),
                subHeader: subTitle ? this.translate.instant(subTitle) : '',
                backdropDismiss: true,
                buttons: [{
                    text: this.translate.instant('COMMON.OK'),
                    handler: () => {
                        resolve('success');
                    }
                }]
            });
            await alert.present();
        });
    }

    public criticalFullStatusError(title: string, status: number, message: string, tryAgain: boolean, thankYou: boolean, subTitle?: string): Promise<any> {

        return new Promise((resolve, reject) => {

            this.translate.get([title, message, subTitle || '', 'DIALOGS.OK']).subscribe(async (res: string[]) => {
                let alert = await this.alertCtrl.create({
                    header: res[title],
                    message: res[message] + ' ' + this.getFullStatusError(status, tryAgain, thankYou), //TODO: how concat message + status error?
                    subHeader: res[subTitle],
                    buttons: [{
                        text: 'OK',
                        handler: () => {
                            resolve('success');
                        }
                    }]
                });
                await alert.present();
            }, (err: any) => {
                reject(err);
            });

        });

    }

    public confirm(title: string, message: string): Promise<Object> {

        return new Promise(async (resolve, reject) => {

            let alert = await this.alertCtrl.create({
                header: this.translate.instant(title),
                message: this.translate.instant(message),
                cssClass: 'common-alert',
                buttons: [ {
                    text: this.translate.instant('GLOBAL_LABEL_YES'),
                    handler: () => {
                        resolve(true);
                    }
                },
                {
                    text: this.translate.instant('GLOBAL_LABEL_NO'),
                    role: 'cancel',
                    handler: () => {
                        resolve(false);
                    }
                },
            ]
            });
            await alert.present();
        });

    }

    public prompt(title: string, inputType: any, placeholder: string, message?: string, subTitle?: string): Promise<any> {

        return new Promise((resolve, reject) => {

            let input_1_value: any = null;

            this.translate.get([title, message || '', subTitle || '', placeholder, 'GLOBAL_LABEL_NO', 'GLOBAL_LABEL_YES']).subscribe(async (res: string[]) => {
                let alert = await this.alertCtrl.create({
                    header: res[title],
                    message: res[message],
                    subHeader: res[subTitle],
                    inputs: [
                        {
                            name: 'input',
                            label: res[placeholder],
                            type: inputType,
                            value: 'input_1',
                            handler: data => {
                                if (inputType == 'checkbox')
                                    input_1_value = data.checked;
                                else input_1_value = data.value;
                            }
                        }
                    ],
                    buttons: [{
                        text: res['GLOBAL_LABEL_NO'],
                        role: 'cancel',
                        handler: data => {
                            resolve({ button: false, input: input_1_value });
                        }
                    }, {
                        text: res['GLOBAL_LABEL_YES'],
                        handler: data => {
                            resolve({ button: true, input: input_1_value });
                        }
                    }]
                });
                await alert.present();
            }, (err: any) => {
                reject(err);
            });

        });
    }

    public async info(text: string, duration = 2000) {
        this.currentToast = await this.toastCtrl.create({
            message: this.translate.instant(text),
            duration
        });
        await this.currentToast.present();
    }

    public async appRestartToast(text: string) {
        this.currentToast = await this.toastCtrl.create({
            message: this.translate.instant(text),
            cssClass: 'toast-popup',
            duration: 4000
        });
        await this.currentToast.present();
    }

    public async notify(text: string, dismissOnClose?: boolean, dismissOnPageChange?: boolean) {
        this.closeCurrentToast();

        this.currentToast = await this.toastCtrl.create({
            message: this.translate.instant(text),
            cssClass: 'toast-popup',
            duration: dismissOnClose ? 0 : 3000,
            // position:'middle',
        
        });
        await this.currentToast.present();

    }

    private closeCurrentToast() {
        if (this.currentToast) {
            this.currentToast.dismiss();
        }
    }

    public async showModal(token: string, showCheckbox, cssClass?: string): Promise<string> {
        let showSafetyTagFooter = token ? await this.storage.get(storageKeys.showSafetyTagHint) : '';
        if (!showSafetyTagFooter) {
            const modal = await this.modalCtrl.create({
                component: DisclaimerModal,
                cssClass: cssClass ?? 'disclaimerModal',
                animated: false,
                componentProps: {
                    showCheckbox,
                    token
                }
            });
            await modal.present();
        }
        return Promise.resolve(showSafetyTagFooter);
    }

    public async openDisclaimerLink(): Promise<void> {
        const modal = await this.modalCtrl.create({
            component: DisclaimerModal,
            cssClass: 'disclaimerModal',
            animated: false,
            componentProps: {
                showCheckbox: false,
                token: false
            }
        });
        await modal.present();
    }


    public async toggleSafetyTagFooter(status: boolean) {
        let showSafetyTagFooter = status ? 'true' : '';
        this.storage.set(storageKeys.showSafetyTagHint, showSafetyTagFooter);
    }
}
