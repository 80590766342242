import { AbstractControl } from '@angular/forms';

export const EmailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const PasswordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}/;

export const NumberPattern = /^[0-9]+$/;

export const AlphaNumericPatter = /^[a-z0-9]+$/i;
export const onlyAlphabets = /^[a-zA-Z]*$/;
// [A-Za-z]

const SpecialCharacters = /^[0-9a-zA-Z \_$&#@]+$/;

export function ValidateSpaces(control: AbstractControl) {
  let fields = String(control.value);
  if (fields) {
    if (!fields.trim()) {
      return { spacesError: true }
    }
  }
  return null;
}

export function ValidateAlphabet(control: AbstractControl) {
  let fields = control.value;
  if (fields) {
    if (!fields.match(onlyAlphabets)) {
      return { AlphaError: true };
    }
  }
  return null;
}

export function ValidateNumber(control: AbstractControl) {
  let fields = control.value;
  if (fields) {
    if (!fields.match(NumberPattern)) {
      return { numberError: true };
    }
  }
  return null;
}

export function ValidateAlphaNumeric(control: AbstractControl) {
  let fields = control.value;
  if (fields) {
    if (!fields.match(AlphaNumericPatter)) {
      return { alphaNumberError: true };
    }
  }
  return null;
}

export function validateAlphaNumericString(name: string) {
  let regExp = new RegExp(SpecialCharacters);
  return regExp.test(name);
}
