import { Storage } from '@ionic/storage';

export abstract class StorageMigration {

    constructor(public from: number,
        public to: number) {

    }

    async migrate(storage: Storage): Promise<number> {
        console.log(`Start: Migration from ${this.from} to ${this.to}`);
        await this.internalMigrate(storage);
        console.log(`Finish: Migration from ${this.from} to ${this.to}`);
        return this.to;
    }

    abstract internalMigrate(storage: Storage);
}