import { Injectable } from '@angular/core';
import { Article } from '../../types/types';
import bwipjs from 'bwip-js';

export interface ArticleLabel {
  gtin?: string,
  expiry?: string,
  batchNumber?: string,
  articleNumber?: string,
  materialNumber?: string
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeGeneratorService {

  separator: string = String.fromCharCode(29);
  identifiers = {
    'gtin': '01',
    'expiryDate': '17',
    'batchNumber': '10',
    'articleNumber': this.separator + '123',
    'materialNumber': this.separator + '240'
  };

  constructor() { }

  public generateDataMatrixFromArticle(article: Article, extraField: string = ''): Promise<any> {
    const tempComponent = document.createElement('canvas');
    return new Promise(async (resolve, reject) => {
      try {
        bwipjs.toCanvas(tempComponent, {
            bcid: 'datamatrix',
            scale: 3,
            text: this.formatArticleData(article),
        }); 
        resolve(tempComponent.toDataURL('image/png'));
      } catch (error) {
        console.log("Erroe", error);
        reject(error);
      }
    });
  }

  private formatArticleData(article: Article): string {
      // TODO: Update when GTIN, expiryDate and batchNumber were provided
      const values = {
        'gtin': null,
        'expiryDate': null,
        'batchNumber': null,
        'articleNumber': article.articleNumber,
        'materialNumber': article.materialNumber
      }
      let data: string = this.separator;
      Object.keys(this.identifiers).forEach(field => {
        if (values[field]) data += this.identifiers[field] + values[field];
      });

      return data;
    }
}
