import { storageKeys } from './storage-keys';
import { Injectable } from '@angular/core';
import { DialogService } from '../services/dialog.service';
import { Article, FavoritesList } from '../types/types';
import { Storage } from '@ionic/storage';
import { SafetyDataAPI } from './safety-data-api.service'
import { AuthentService } from './authent.service';
import { SDSService } from '../services/sds.service';
import {ConnectivityProvider} from './connectivity/connectivity';
import {TokenService} from '../services/token.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteStoreService {

  private isAuthenticated: boolean = false;
  private favoritesList$: FavoritesList[];
  private archivedList$:FavoritesList[]

  constructor(private storage: Storage,
    private safetyAPIService: SafetyDataAPI,
    private dialogService: DialogService,
    private tokenService: TokenService,
    private connectivity: ConnectivityProvider,
    public sdsService: SDSService,
    private authentService: AuthentService) {
    this.authentService.listenAuthentication().subscribe(tokenExistance => {
      this.isAuthenticated = tokenExistance;
    });
  }

  public async getAllFavoritesLists(): Promise<FavoritesList[]> {
    try {
      if (this.isAuthenticated || this.tokenService.token) {
        if(this.connectivity.checkNetworkConnectivity()) {
          this.favoritesList$ = await this.safetyAPIService.getFavoritesLists();
          this.downloadFavourite();
        } else {
          this.favoritesList$ = await this.storage.get(storageKeys.favoritesLists) || [];
        }
      } else {
        this.favoritesList$ = await this.storage.get(storageKeys.favoritesLists) || [];
        this.updateOfflineStorageForNotLoggedInUser();
      }
      return this.favoritesList$;
    } catch {
      console.log('can\'t get all favorite list');
      return [];
    }
  }

  public async getAllArchivedLists(): Promise<FavoritesList[]> {
    try {
      if (this.isAuthenticated || this.tokenService.token) {
        if(this.connectivity.checkNetworkConnectivity()) {
          this.archivedList$ = await this.safetyAPIService.getArchivedLists();
        } else {
          this.archivedList$ = await this.storage.get(storageKeys.archivedLists) || [];
        }
      } else {
        this.archivedList$ = await this.storage.get(storageKeys.archivedLists) || [];
      }
      return this.archivedList$;
    } catch {
      console.log('can\'t get all favorite list');
      return [];
    }
  }

  maitainExpandStateFavList(favLists: FavoritesList[]) {
    this.favoritesList$ = favLists;
  } 


  private  updateOfflineStorageForNotLoggedInUser() {
    this.favoritesList$.forEach(favItem => {
        favItem.articles.forEach(async item => {
          if(!item.offlineAvailable) {
            await this.storage.remove(item.materialNumber);
          }
        });
    });
    this.downloadFavourite();
  }

  async downloadFavourite(favoritesLists: FavoritesList[] = this.favoritesList$) {
    for (const listItem of favoritesLists) {
      for (const item of listItem.articles) {
          if(!item.offlineAvailable){
            await this.storage.remove(item.materialNumber);
            continue;
          };
          this.storage.get(item.materialNumber).then(value => {
            if(!value) {
              this.sdsService.loadCurrentArticleDetails(item).subscribe(async sds => {
                await this.storage.set(item.materialNumber, sds);
              });
            }
        });
      }
    }
    await this.storage.set(storageKeys.favoritesLists, favoritesLists);
  }

  // private async syncFavouriteListWithOfflineStorage() {
  //   try {
  //     this.favoritesList$ = await this.safetyAPIService.getFavoritesLists();
  //     this.downloadFavourite();
  //   } catch (error) {
  //     console.log('Unable to sync');
  //   }
  // }

  public async saveMultiplesFavoritesLists(favoritesLists: FavoritesList[]): Promise<{success: boolean, error?: string}>{
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.createMultiplesFavoritesList(favoritesLists);
        // this.syncFavouriteListWithOfflineStorage();
      } else {
        this.favoritesList$ = favoritesLists;
        await this.storage.set(storageKeys.favoritesLists, this.favoritesList$ );
        this.updateLocalStorage(this.favoritesList$);
      }
      return { success: true}
    } catch (error) {
      return { success: false, error: 'UNEXPECTED_ERROR' };
    }
  }

  public async removeAllFavoritesLists(): Promise<{success: boolean, error?: string}> {
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.deleteAllFavoritesList();
      }
      await this.storage.remove(storageKeys.favoritesLists);
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async removeAllArchivedLists(): Promise<{success: boolean, error?: string}> {
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.deleteAllArchivedList();
      }
      await this.storage.remove(storageKeys.archivedLists);
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async addFavoriteToList(favorite: Article, favoritesListName: string): Promise<{success: boolean, error?: string}> {
    console.log("addFavoriteToList", this.favoritesList$);
    let list = this.getFavoriteListByName(favoritesListName);
    if (!list) return { success: false, error: 'LIST_DOES_NOT_EXIST'};
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.addFavoriteToList(list.id, favorite);
        // this.syncFavouriteListWithOfflineStorage();
        return;
      } else {
        let listSelect = this.favoritesList$.find((favList: FavoritesList) => favList.name === list.name);
        listSelect.articles = [...list.articles, favorite];
        this.updateLocalStorage(this.favoritesList$);
      }
      return { success: true}
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async removeFavoriteFromAllList(favorite: Article): Promise<{success: boolean, error?: string}> {
    try {
      if (this.isAuthenticated) {
        let promises = [];
        this.favoritesList$.forEach((list: FavoritesList) => {
          list.articles.forEach( article => {
            if (article.materialNumber === favorite.materialNumber) {
              promises.push(this.safetyAPIService.removeFavoriteToList(list.id, article.id));
            }
          });
        });
        if(promises.length > 0) {
          await Promise.all(promises);
        } 
      } else {
        this.favoritesList$.forEach( list => {
          list.articles = list.articles.filter(article => article.materialNumber != favorite.materialNumber);
        });
        await this.updateLocalStorage(this.favoritesList$);
      }
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async removeFavoriteToList(favorite: Article, favoritesListName: string): Promise<{success: boolean, error?: string}>{
    try {
      let listSelect = this.favoritesList$.find((favList: FavoritesList) => favList.name === favoritesListName);
      if (!listSelect) return { success: false, error: 'LIST_DOES_NOT_EXIST'};
      if (this.isAuthenticated) {
        await this.safetyAPIService.removeFavoriteToList(listSelect.id, favorite.id);
      } else {
        listSelect.articles = listSelect.articles.filter(article => article.materialNumber != favorite.materialNumber);
        this.updateLocalStorage(this.favoritesList$);
      }
      await this.storage.remove(favorite.materialNumber);
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async createFavoriteList(name: string, articles: Article[], uuid = ''): Promise<{success: boolean, error?: string}> {
    try {
      let list: FavoritesList = {
        name: name,
        articles: articles,
        offlineAvailable: false,
        expandList: false
      }
      if (uuid) {
        let status = await this.listExists(name);
        if (status) {
          return { success: false, error: 'LIST_EXIST'};
        }
      }
      return await this.saveFavoritesList(list, articles);
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async saveFavoritesList(list: FavoritesList, articles: Article[] = []): Promise<{success: boolean, error?: string}> {
    try {
      list.name = this.listExists(list.name) ? this.setNewName(list.name) : list.name;
      let validation = this.validateListName(list.name);
      if (!validation.success) return validation;
      if (this.isAuthenticated) {
        await this.safetyAPIService.createFavoritesList(list);
        // this.syncFavouriteListWithOfflineStorage();
      } else {
        this.favoritesList$ = [...this.favoritesList$, list];
        await this.updateLocalStorage(this.favoritesList$);
      }
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async updateFavoritesList(list: FavoritesList, newName: string): Promise<{ success: boolean, error?: string }> {
    try {
      if (!this.favoritesList$) return { success: false, error: 'FAVORITE_LISTS_IS_EMPTY' };
      let validation = this.validateListName(newName);
      if (!validation.success) return validation;

      if (this.isAuthenticated) {
        list.name = newName;
        await this.safetyAPIService.updateFavoritesList(list);
        // this.syncFavouriteListWithOfflineStorage();
      } else {
        let listSelect = this.favoritesList$.find((favList: FavoritesList) => favList.name === list.name);
        listSelect.name = newName;
        await this.updateLocalStorage(this.favoritesList$);
      }
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async updateFavoritesListOfflineUsage(list: FavoritesList, lists: FavoritesList[]): Promise<{ success: boolean, error?: string }> {
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.updateFavoritesList(list);
      }
      this.downloadFavourite(lists);
      return Promise.resolve({ success: true });
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async deleteFavoritesList(favoriteList: FavoritesList): Promise<{success: boolean, error?: string}> {
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.deleteFavoritesList(favoriteList.id);
        // this.syncFavouriteListWithOfflineStorage();
      } else {
        let lists = this.favoritesList$.filter(favList => favList.name != favoriteList.name);
        this.updateLocalStorage(lists);
      }
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }

  public async deleteArchivedList(favoriteList: FavoritesList): Promise<{success: boolean, error?: string}> {
    try {
      if (this.isAuthenticated) {
        await this.safetyAPIService.deleteArchivedList(favoriteList.id);
      } else {
        let lists = this.archivedList$.filter(favList => favList.name != favoriteList.name);
        await this.storage.set(storageKeys.archivedLists, lists);
        await this.getAllArchivedLists();
      }
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }
/*Remove Archive in the list */
  public async removeArchivedToList(favorite: Article, favoritesListName: string): Promise<{success: boolean, error?: string}>{
    try {
      let listSelect = this.archivedList$.find((favList: FavoritesList) => favList.name === favoritesListName);
      if (!listSelect) return { success: false, error: 'LIST_DOES_NOT_EXIST'};
      if (this.isAuthenticated) {
        await this.safetyAPIService.removeArchivedToList(listSelect.id, favorite.id);
      } else {
        listSelect.articles = listSelect.articles.filter(article => article.materialNumber != favorite.materialNumber);
        await this.storage.set(storageKeys.archivedLists, this.archivedList$);
        await this.getAllArchivedLists();
      }
      await this.storage.remove(favorite.materialNumber);
      return { success: true };
    } catch (error) {
      this.checkNetworkError();
      return Promise.reject({success: false, error: 'UNEXPECTED_ERROR'});
    }
  }



  private async updateLocalStorage(lists: FavoritesList[]) {
    await this.storage.set(storageKeys.favoritesLists, lists);
    await this.getAllFavoritesLists();
  }

  private listExists(name: string): boolean {
    return this.favoritesList$.some(favList => favList.name.toUpperCase() === name.toUpperCase());
  }

  public getFavoriteListByName(favoritesListName: string): FavoritesList {
    return this.favoritesList$.find(favoritesList => favoritesList.name === favoritesListName);
  }

  private validateListName(listName: any): {success : boolean, error?: string} {
    if (typeof listName !== 'string' || !listName) {
      return { success: false, error: 'FAVORITES_LIST_NAME_INVALID' };
    } else if (this.listExists(listName)) {
      return { success: false, error: 'FAVORITES_LIST_EXIST' }
    } else {
      return { success: true }
    }
  }

  private setNewName(listName: string) {
    let name = listName;
    if (this.listExists(name)) {
      let suffix: number = 2;
      while (this.listExists(name + '(' + suffix + ')')) suffix++;
      name = name + '(' + suffix + ')';
    }
    return name;
  }

  private checkNetworkError() {
    if (!this.connectivity.checkNetworkConnectivity()) {
      this.dialogService.critical('INTERNET_DISCONNECTED', 'offline_notification');
    }
  }
}
