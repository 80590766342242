import { Component, Input, ViewChild } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute, NavigationStart } from '@angular/router'
import { Observable, Subscription, timer } from 'rxjs';
import { tap, map, } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Article, SearchResult, ISettings, SafetyDataSheet } from '../../types/types';
import { LoadingProvider } from '../../services/loading/loading';
import { DialogService } from '../../services/dialog.service';
import { SDSService } from '../../services/sds.service';
import { ArticleLabel } from '../../services/label-scanner/label-scanner.service';
import { SettingsService } from '../../services/settings.service';
import { Storage } from '@ionic/storage';
import { storageKeys } from '../../services/storage-keys';
import { environment } from '../../env/env';
import { CN } from '../../constants';
import { NavController, PopoverController, ModalController } from '@ionic/angular';
import { ConnectivityProvider } from '../../services/connectivity/connectivity';
import { SearchPage } from 'src/app/search/search';
import { ExternalLinkService } from '../../services/external-link.service';
import { SearchListModal } from '../search-list-modal/search-list-modal';


@Component({
    selector: 'footer-menu',
    templateUrl: 'footer-menu.html',
    styleUrls: ['footer-menu.scss'],
})
export class FooterMenuComponent {
     currentPlatform = Capacitor.getPlatform();
    @Input() showFoot:boolean;
    public activeUrl: string;

    constructor(private router: Router,
    public external: ExternalLinkService,
    private settingsService: SettingsService,
    public sdsService: SDSService,
    ){

}

    ngOnInit(){
        this.router.events.forEach((event) => {
              if (event instanceof NavigationStart) {
                this.activeUrl = event.url;          
              }      
          });

        this.currentPlatform = Capacitor.getPlatform();
    }

    onClickHome(){
        this.sdsService.search$.next("");
        this.router.navigateByUrl('/landingpage', {replaceUrl: true})
    }

    onClickFav(){
        this.router.navigateByUrl('/favoriteLists', {replaceUrl: true})
    }

    onClickHistory(){
        this.router.navigateByUrl('/history', {replaceUrl: true})
    }

    onClickMore(){
        this.router.navigateByUrl('/moreOptions', {replaceUrl: true})
    }
}