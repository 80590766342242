import { NgModule, ModuleWithProviders, Provider, InjectionToken, Optional, Inject } from '@angular/core';
import { StorageMigrationService } from './storage-migration.service';
import { StorageMigrationOptions } from './storage-migration-options';

export interface StorageMigrationConfig {
    storageMigrationOptions?: Provider;
}

export const initialStorageMigrationOptions: Provider = {
    provide: StorageMigrationOptions,
    useValue: {
        version: 0,
        versionKey: 'version',
        migrations: []
    }
}

@NgModule({})
export class StorageMigrationModule {
    static forRoot(config?: StorageMigrationConfig): ModuleWithProviders<StorageMigrationModule> {
        return {
            ngModule: StorageMigrationModule,
            providers: [
                StorageMigrationService,
                config ?
                    config.storageMigrationOptions || initialStorageMigrationOptions
                    : initialStorageMigrationOptions
            ]
        }
    }
}
