import { Injectable } from '@angular/core';
import { BarcodeScanner, BarcodeScannerOptions } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'src/app/types/types';
let articleLabelNumber = '';
export interface ArticleLabel {
    gtin?: string,
    expiry?: string,
    batchNumber?: string,
    materialNumber?: string,
    articleNumber?: string
}

@Injectable({
    providedIn: 'root',
})
export class LabelScannerService {

    constructor(private barcodeScanner: BarcodeScanner,
                private translate: TranslateService) {
    }

    scanArticleLabel(): Promise<ArticleLabel> {
        return new Promise((resolve, reject) => {
            this.barcodeScanner.scan(this.barcodeOptions())
                .then(scanResult => {
                    console.log('scanResult', scanResult);
                    if (scanResult.cancelled) {
                        resolve(null);
                    } else {
                        const articleLabel = this.mapBarcodeData(scanResult.text);
                        resolve(articleLabel);
                    }
                })
                .catch(error => reject(error));
        });
    }

    private barcodeOptions(): BarcodeScannerOptions {
        return {
            formats: 'DATA_MATRIX',
            prompt: this.translate.instant('LABEL_SCAN_PROMPT'),
            // set to 1500 for testing purpose to see content on screen
            resultDisplayDuration: 0
        };
    }


    //TODO: Padding of leading zeros logic needs update once database cleanup activities are done.
         
    private padLeadingZeros(materialNo: string): string {
        var formattedMaterialNumber: string;
        if(isNaN(Number(materialNo)) && materialNo !='undefined') {
            formattedMaterialNumber = materialNo;
          } else { 
            if(materialNo.length < 11 && !materialNo.includes('00000000')) { 
                formattedMaterialNumber = '00000000' + materialNo
            } else if (materialNo.length <= 13 && !materialNo.includes('00000000')) {
                formattedMaterialNumber = '00000000' + materialNo.substring(3, 13);
            } else {
                formattedMaterialNumber = materialNo;
            }
          }
          return formattedMaterialNumber;
    }

    private extractMaterial(tempMaterialNumber: string) {
            const barcodeSequence = [
                {startsWith: '01',substringLen: 2,length: 16, identifier: 'gtin'},
                {startsWith: '11',substringLen: 2,length: 8, identifier: 'prodDate'},
                {startsWith: '15',substringLen: 2,length: 8, identifier: 'bestBeforeDate'},
                {startsWith: '17',substringLen: 2,length: 8, identifier: 'expiryDate'},
                {startsWith: '422',substringLen: 3, length: 6, identifier: 'countryOrigin'},
                {startsWith: '10',substringLen: 2,length: 22, identifier: 'batch'},
                {startsWith: '123',substringLen: 3, length: 3, identifier: 'articleNumber'},
                {startsWith: '240',substringLen: 3, length: 3, identifier: 'material'},
            ];

            let modified=tempMaterialNumber;
            let is240Present = false;
            for (let item of barcodeSequence) {
                if (modified !='undefined' && modified.substring(0, item.substringLen) == item.startsWith) {
                    if (modified.substring(0, item.substringLen) == '240') {
                        is240Present = true;
                        if (modified.length >= 33) {
                            modified = modified.substring(3,33);
                            //Eliminated the logic as per the MMS-1874 story
                            //modified = this.padLeadingZeros(modified);
                            modified = this.excludeSequence(modified, item.length);
                            //modified = modified.replace(/-*\/*\.*/g, '');
                            break;
                    } else {
                            //modified = this.padLeadingZeros(modified);
                            if (modified.startsWith('240')) {
                                modified = this.excludeSequence(modified, item.length);
                            }
                            //modified = modified.replace(/-*\/*\.*/g, '');
                            break;
                        }
                    } else if (modified.substring(0, item.substringLen) == '123') {
                        if (articleLabelNumber == '' && modified.length >= 33) {
                            articleLabelNumber = modified.substring(3,33);
                            articleLabelNumber = this.excludeSequence(articleLabelNumber, item.length);
                            articleLabelNumber = articleLabelNumber.replace(/-*\/*\.*/g, '');
                            break;
                    } else {
                            modified = this.excludeSequence(modified, item.length);
                            modified = modified.replace(/-*\/*\.*/g, '');
                            articleLabelNumber = modified;
                            break;
                        }
                    };
                    modified = this.excludeSequence(modified, item.length);
                }
            }
            const materailNumber = is240Present ? modified : articleLabelNumber;
            return { materailNumber, is240Present };

    }

    public mapBarcodeData(barcodeData: string): ArticleLabel {
        let splitBarcode = barcodeData.split(String.fromCharCode(29));
        let tempMaterialNumber = '';
        for (let item of splitBarcode) {
            const {materailNumber, is240Present} = this.extractMaterial(item);
            tempMaterialNumber = materailNumber;
            if (is240Present) break;
        }

        let firstIdentifierPosition = 1;
        return {
            gtin: this.getGTIN(splitBarcode[firstIdentifierPosition]),
            expiry: this.getExpiryDate(splitBarcode[firstIdentifierPosition]),
            batchNumber: this.getBatchNumber(splitBarcode[firstIdentifierPosition]),
            materialNumber: tempMaterialNumber,
            articleNumber: articleLabelNumber,
        };
    }

    private excludeSequence(data = '', length: number): string {
        return data.substr(length);
    }

    private getGTIN(data: string = ''): string {
        //identifier 01: gtin 14 digits
        if (data.substring(0, 2) === '01') {
            return data.substring(2, 16);
        }
        return null;
    }

    private getExpiryDate(data: string = ''): string {
        //identifier 17: expiry date yymmdd
        if (data.substring(16, 18) === '17') {
            return data.substring(22, 24) + '.' + data.substring(20, 22) + '.' + data.substring(18, 20);
        }
        return null;
    }

    private getBatchNumber(data: string = ''): string {
        //identifier 10: batch number variable length
        if (data.substring(24, 26) === '10') {
            return data.substring(26);
        }
        return null;
    }

    private getMaterialNumber(data: string = ''): string {
        //identifier 240: material number variable length
        if (data.substring(0, 3) === '240') {
            return data.substring(3).replace(/-*\/*\.*/g, '');
        }
        return null;
    }

    private getArticleNumber(materialNumber: string = ''): string {
        if (materialNumber) {
            return materialNumber.substring(0, 6);
        }
        return null;
    }
}
