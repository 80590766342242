import { Injectable } from '@angular/core';

import { DialogService } from './dialog.service';

import { IError } from '../classes/_interfaces';


@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    constructor(private dialogService: DialogService) {

    }

    public handle(error: IError): void {
        console.error(JSON.stringify(error));
        this.dialogService.critical('ERROR.UNEXPECTED_ERROR', JSON.stringify(error), 'ERROR.INSTRUCTIONS');
    }

}
