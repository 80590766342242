import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, from, of } from "rxjs";
import { map, filter, mergeMap, flatMap, catchError, tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    public initialized: boolean = false;

    constructor(private storage: Storage) {
    }

    public get(key: string): Observable<any> {
        return from(this.storage.get(key));
    }

    public set(key: string, value: any): void {

        this.storage.set(key, value);
    }

    public remove(key: string): void {

        this.storage.remove(key);
    }

    public clear(): void {

        this.storage.clear()
    }

}
