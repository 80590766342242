import { IDeleteAccount } from './../classes/_interfaces';
import { Injectable } from '@angular/core';
import { IHttpError } from '../classes/_interfaces';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../env/env';


type HttpConfig = {
  baseURL?: String
  token?: String,
  responseType?: String
}

@Injectable({
  providedIn: 'root',
})
export class HttpService {

    public defaultConfig: HttpConfig = {baseURL: environment.baseUrl};
    public safetyDataAPIBaseURL = environment.safetyDataAPIBaseURL

    constructor(public http: HttpClient) {
    }

    public get(apiModule: string, config = this.defaultConfig): Promise<any> {
      let options = {};
      if (config.token) {
        options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + config.token })
      }
      return new Promise((resolve, reject) => {
          this.http.get(config.baseURL + apiModule, options)
              .subscribe(
                  res => resolve(res),
                  err => reject(this.handleError(err)));
      });
    }

    public post(apiModule: string, params?: Object, config = this.defaultConfig): Promise<any> {
      let options = {};
      if (config.token) {
        options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + config.token })
      }
      if (config.responseType) {
        options['responseType'] = config.responseType;
      }

      return new Promise((resolve, reject) => {
          this.http.post(config.baseURL + apiModule, JSON.stringify(params), options)
              .subscribe(
                  res => resolve(res),
                  err => reject(this.handleError(err)));
      });
    }

    public registerPost(apiModule: string, params?: Object, config = this.defaultConfig): Promise<any> {
      let options = {};
      if (config.token) {
        options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + config.token })
      }
      if (config.responseType) {
        options['responseType'] = config.responseType;
      }

      return new Promise((resolve, reject) => {
          this.http.post(config.baseURL + apiModule, JSON.stringify(params), options)
              .subscribe(
                  res => resolve(res),
                  err => reject(err));
      });
    }

    public put(apiModule: string, params: Object, config = this.defaultConfig): Promise<any> {
      let options = {};
      if (config.token) {
        options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + config.token })
      }
      return new Promise((resolve, reject) => {
        this.http.put(config.baseURL + apiModule, JSON.stringify(params), options)
          .subscribe(
              res => resolve(res),
              err => reject(this.handleError(err)));
      });
    }

    public delete(apiModule: string, config = this.defaultConfig): Promise<any> {
      let options = {};
      options['responseType'] = "text";
      if (config.token) {
        options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + config.token })
      }
      return new Promise((resolve, reject) => {
        this.http.delete(config.baseURL + apiModule, options)
          .subscribe(
            res => resolve(res),
            err => reject(this.handleError(err)));
      });
    }

    public deleteAccount(url: string, payload: IDeleteAccount, config = this.defaultConfig): Promise<any> {
      let options = {};
      // if (payload.userId) {
      //   options['params'] = new HttpParams({fromString: `userId=${payload.userId}`});
      // }
      if (config.token) {
        options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + config.token })
      }
      return new Promise((resolve, reject) => {
        this.http.request('delete',this.safetyDataAPIBaseURL + url, options)
          .subscribe(
            res => resolve(res),
            err => reject(this.handleError(err.error)));
      });
    }

    public getUserData(url: string, userId: number, token: any): Promise<any> {
      let options = {};
      options['headers'] = new HttpHeaders({ 'Authorization': 'Bearer ' + token })
      // options['params'] = new HttpParams({fromString: `userId=${userId}`});
      return new Promise((resolve, reject) => {
        this.http.request('get',this.safetyDataAPIBaseURL + url, options)
          .subscribe(
            res => resolve(res),
            err => reject(err));
      });
    }

    public getCatpchaImage(): Promise<any> {
      return new Promise((resolve, reject) => {
        this.http.get(this.safetyDataAPIBaseURL + '/user/captcha', {observe: 'response', responseType: 'arraybuffer'}).subscribe(value => {
          const captchaObj = {
            capSid: value.headers.get('cap-sid'),
            base64Img: this.arrayBufferToBase64(value.body),
          };
          resolve(captchaObj);
        }, error => {
          reject(error);
        });
      });
    }

    private handleError(error: any | any): any {
        // Todo: Implement logging Infrastructure
        let err: IHttpError = {};

        if (error instanceof HttpErrorResponse) {
            err.status = error.status;
            err.statusText = error.statusText;
            err.message = error.message;
            // if (error.json().errors && error.json().errors.length === 1) {
            //   err.title = error.json().errors[0].title;
            //   err.message = error.json().errors[0].detail;
            // } else {
            //   err.message = error.json().errors;
            // }

        } else {
            err.message = error.message || error.toString();
        }
        return err;
        // return Observable.throw(err);

    }

    private arrayBufferToBase64( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
  }

}
