import { storageKeys } from './../storage-keys';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage.service';

@Injectable({
    providedIn: 'root',
})
export class GridProvider {

    public frequencies = [20, 8, 5, 3, 3, 4, 2, 1, 1, 2, 4, 5, 5, 1, 0, 1];
    // public frequencies = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    public total = this.getTotal();

    constructor(public storage: StorageService) {

    }

    loadFrequencies(): Promise<any> {
        return new Promise<any>((resolve => {
            this.storage.get(storageKeys.gridFrequencies).subscribe((frequenices) => {
                if (frequenices) {
                    this.frequencies = frequenices;
                    this.total = this.getTotal();
                    resolve('');
                } else {
                    resolve('');
                }
            });
        }));
    }

    registerClick(index) {
        this.total++;
        this.frequencies[index - 1]++;
        this.storage.set(storageKeys.gridFrequencies, this.frequencies);
    }

    private getTotal() {
        let i: number = 0;
        for (let n of this.frequencies) {
            i += n;
        }
        return i;
    }
}
