import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, IonCheckbox } from '@ionic/angular';
import { Events } from 'src/app/services/events/events';

@Component({
  selector: 'disclaimer-modal',
  templateUrl: './disclaimer-modal.html',
  styleUrls: ['./disclaimer-modal.scss'],
})
export class DisclaimerModal implements OnInit {

  @Input() showCheckbox: boolean;
  @Input() token: any;
  @ViewChild('checkbox') checkbox: IonCheckbox;

  isChecked = false;

  constructor(
    private modalCtrl: ModalController,
    private events: Events,
  ) { 
  }
  
  ngOnInit() {
    this.checkbox.checked = this.isChecked;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  checkBoxClick() {
    if (this.checkbox?.checked) {
      this.events.publish('disclaimer');
    }
    this.closeModal();
  }

}
