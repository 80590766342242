import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import wx from 'weixin-js-sdk'
import { Router, NavigationExtras, NavigationStart } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { tap, map } from "rxjs/operators";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import {
  Article,
  SearchResult,
  ISettings,
  SafetyDataSheet,
} from "../../types/types";
import { LoadingProvider } from "../../services/loading/loading";
import { DialogService } from "../../services/dialog.service";
import { SDSService } from "../../services/sds.service";
import {
  ArticleLabel,
  LabelScannerService,
} from "../../services/label-scanner/label-scanner.service";
import { SettingsService } from "../../services/settings.service";
import { Storage } from "@ionic/storage";
import { storageKeys } from "../../services/storage-keys";
import { environment } from "../../env/env";
import { CN } from "../../constants";
import {
  NavController,
  PopoverController,
  ModalController,
  IonSearchbar,
} from "@ionic/angular";
import { ConnectivityProvider } from "../../services/connectivity/connectivity";
import { ExternalLinkService } from "../../services/external-link.service";
import { SearchListModal } from "../search-list-modal/search-list-modal";
import { LoadingService } from "../../services/loading.services";
import { AuthentService } from "src/app/services/authent.service";
import { LogoutPopoverComponent } from "../logout-popover/logout-popover";
import {Location} from '@angular/common';
import { DeviceDetectorService } from "ngx-device-detector";
import { WebScanner } from '../web-scanner/web-scanner'


@Component({
  selector: "header-menu",
  templateUrl: "header-menu.html",
  styleUrls: ["header-menu.scss"],
})
export class HeaderMenuComponent {
  @Input() showHead: boolean;
  // @Input() showFooter:boolean;
  @ViewChild("popover") popover: any;
  @ViewChild("searchbar") searchbar: IonSearchbar;
  currentPlatform = Capacitor.getPlatform();
  public isWeb = true;
  public isNoSearchText = true;
  public isScanning: boolean = false;
  searchHasFocus: boolean = false;
  public searchTerm: string = "";
  public searchResults: Article[];
  public searchHits = -1;
  public searchOffset = 0;
  private searchLimit = 20;
  public isHeaderLogoVisible:boolean = true;
  public help: boolean = true;
  private filterOutIncludes = ['bulk', 'var', 'intr'];
  private filterOutEnd = ["0000", "9999"];
  public filterOut = "qua";
  chinaWebPortalEnabled = environment.validityArea == CN;
  disabledForWhile = false;
  settings: ISettings;
  private browserType = "";
  public isBackVisible = false;
  public showScroll = false;
  public loginUserName: any;
  public tokenKey: any;
  public isHeaderOptionVisible = true;
  public isWeChat = false

  // settings: Observable<ISettings>;
  searchSubscription: Subscription;
  detailsSubscription: Subscription;
  connectivitySubscription: Subscription;
  settingsSubscription: Subscription;
  logoutpopover: any;
  public onLogoutClicked = true;
  showScanner: boolean = true;
  screenWidth: number;
  public isDesktop: boolean;

  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
    public loading: LoadingProvider,
    public sdsService: SDSService,
    private dialogService: DialogService,
    private settingsService: SettingsService,
    private storage: Storage,
    public navCtrl: NavController,
    private connectivity: ConnectivityProvider,
    public popoverController: PopoverController,
    public external: ExternalLinkService,
    public modalCtrl: ModalController,
    private labelScannerService: LabelScannerService,
    private loadingService: LoadingService,
    public authentService: AuthentService,
    private location: Location
  ) // private routerOutlet: IonRouterOutlet,

  {
    this.settingsSubscription = this.settingsService.settings.subscribe(
      (settings) => (this.settings = settings)
    );

    this.loadingService.mobileBrowserName$.subscribe((value) => {
      this.browserType = value;
    });

    this.authentService.loginCred$.subscribe((loginResponse: any) => {
      this.loginUserName = loginResponse?.name || '';
      localStorage.setItem("loginUser", loginResponse?.name );
    });
    this.loginUserName = localStorage.getItem("loginUser")

    this.isDesktop = this.deviceService.deviceType !== 'tablet' && (this.deviceService.os === 'Mac' || this.deviceService.os === 'Windows');
    
  }

  ngOnInit() {
    var ua = window.navigator.userAgent;
    this.isWeChat = !!ua.match(/MicroMessenger/i)
    if(environment.validityArea === 'CN'&&this.isWeChat) {
      this.initWechat()
    }
    // this.showScanner = localStorage.getItem('showScan') != '1'
    this.sdsService.showScanner$.subscribe((toggleVar: string)=> {
      this.showScanner = toggleVar != '1';
  }); 

    if (Capacitor.getPlatform() != "web") {
      Keyboard.addListener("keyboardWillShow", () => {
        this.hideHelp();
      });

      Keyboard.addListener("keyboardWillHide", () => {
        if (!this.searchResults && !this.loading.loading) {
          this.showHelp();
        }
      });
    }
    this.settingsSubscription = this.settingsService.settings.subscribe(
      (settings) => {
        if (settings) {
          this.showHelp();
          this.resetSearchTerm();
          this.resetSearchResults();
        }
      }
    );

    this.screenWidth = window.innerWidth; 

    this.isDesktop = this.deviceService.deviceType !== 'tablet' && (this.deviceService.os === 'Mac' || this.deviceService.os === 'Windows');

    this.router.events.forEach((event) => {
      if (!this.isDesktop) {
        if (event instanceof NavigationStart) {
          if (event['url'] == '/landingpage' || event['url'] == '/search') {
            this.isHeaderOptionVisible = true;
            this.isHeaderLogoVisible = true;
            this.isBackVisible = false;
          }else if(event['url'] == '/impressum' || event['url'] == '/infocategory' || event['url'] == '/articleGenerate' || 
            event['url'] == '/articleDocuments' || event['url'] =='/tagGenerator' || event['url'] == '/updatetag' ||
            event['url'] == '/favoriteLists-achived'){
            this.isHeaderOptionVisible = false;
            this.isHeaderLogoVisible = false;
            this.isBackVisible = true;
          }else {
            this.isHeaderOptionVisible = false;
            this.isHeaderLogoVisible = true;
            this.isBackVisible = false;
            
          }
        }
      }
      else {
        this.isHeaderOptionVisible = true;
      }
    });
  }

  onTapToTop() {
    this.sdsService.showScroll$.next(this.showScroll);
  }
  async initWechat(){
    const {data:{appId,nonceStr,timeStamp,signature}} = await this.settingsService.setWechatApis('/') 
    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp:timeStamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature,// 必填，签名
      jsApiList: ['scanQRCode'], // 必填，需要使用的 JS 接口列表
    });
  }
  async showPopover(val: any) {
    if (Capacitor.getPlatform() == "web" && this.isDesktop) {
      this.isNoSearchText = true;
      this.searchTerm = val.detail.value;
      this.searchHasFocus = true;
  
      // this.searchArticles(this.searchTerm);
      if (this.searchTerm.length > 2) {
        if (this.popover) {
          this.popover.dismiss();
        }
        this.popover = await this.popoverController.create({
          keyboardClose: false,
          component: SearchListModal,
          componentProps: {
            searchValue: this.searchTerm,
            searchFocus: this.searchHasFocus,
            noSearchText: this.isNoSearchText,
            onSearchClear: () => {
              this.popover.dismiss();
            },
          },
          showBackdrop: false,
          cssClass: "search-product-popover",
        });
        await this.popover.present();
      } else {
        this.router.navigateByUrl("/landingpage", { replaceUrl: true });
        await this.popover.dismiss();
      }
      this.searchbar.setFocus();
    }
    else{
      this.onSearchClick()
    }
  }

  onSearchFocus(){
    const searchFocus = this.searchbar.value.length;    
    this.sdsService.searchFocus$.next(searchFocus);
  }

  async onSearchClick(e?: any) {
    //this.searchHasFocus = true;
    const input = await this.searchbar.getInputElement();

    // this.isBackVisible = true;
    // const searchFocus = this.searchbar.value.length;
    
    // this.sdsService.searchFocus$.next(searchFocus);

    this.router.navigateByUrl('/landingpage', {replaceUrl: true});

    const searchValue = input.value;
    setTimeout(() => {
      this.sdsService.searchFocus$.next(searchValue.length);
      this.sdsService.search$.next(searchValue);
      if (this.popover) {this.popover.dismiss();}
      if (e && (Capacitor.getPlatform() == "android" || Capacitor.getPlatform() == "ios")) {
        Keyboard.hide();
      }
    }, 500)
  }

  public onStopScanning() {
    this.scanStatus(false);
  }

  public async onScannerClick(){    
     if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
        this.scanStatus(true);
        this.resetSearchTerm();
        this.resetSearchResults();
        this.labelScannerService.scanArticleLabel().then((articleLabel: ArticleLabel) => {
            if (articleLabel) {
                this.scanStatus(false);
                this.showScanSuccessfulToast();
                this.loadScannedArticleDetails(articleLabel);
            }
        })
        .catch(error => {
            this.showLabelScanError();
        });
    } else if (environment.validityArea === 'CN') {
      if(this.isWeChat){        
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode","barCode","datamatrix"], // 可以指定扫二维码还是一维码，默认二者都有
          success: (res) => {
            // var result = res.resultStr; //.split(',')[1];
            const result = res.resultStr
            console.log('result627',result)
            const articleLabel = this.labelScannerService.mapBarcodeData(result);
            this.loadScannedArticleDetails(articleLabel);
            this.showScanSuccessfulToast();
          }
        });
      } else {
        (await this.modalCtrl.create({
          component: WebScanner,
          backdropDismiss: false,
          cssClass: 'scannerClass',
          componentProps: {
            onScanText: decodedText => {
              const articleLabel = this.labelScannerService.mapBarcodeData(decodedText);
              this.loadScannedArticleDetails(articleLabel);
              this.showScanSuccessfulToast();
            }
          }
        })).present();
  
      }
    } else {
        if (environment.region = 'UK') {            
            if(this.browserType == 'android') {
                const resp = await this.dialogService.confirm('REQUIRED_MOBILE_APP', 'REQUIRED_MOBILE_APP_MESSAGE_ANDROID');
                if(resp) {
                    this.external.openExternalUrl('https://play.google.com/store/apps/details?id=de.merck.merckproductsafety.googleplay');
                }
            } else if (this.browserType == 'iphone' || this.browserType == 'ipad') {
                const resp = await this.dialogService.confirm('REQUIRED_MOBILE_APP', 'REQUIRED_MOBILE_APP_MESSAGE_IOS');
                if(resp) {
                    this.external.openExternalUrl('https://apps.apple.com/in/app/my-m-safety-sds-label/id1440030082');
                }
            } else {
                this.dialogService.notify('Coming Soon');
            }
        } else {
            this.dialogService.notify('Coming Soon');
        }
    }
}

  private scanStatus(status: boolean) {
    this.isScanning = status;
  }

  private showScanSuccessfulToast() {
    this.dialogService.notify("NOTIFICATION_SCAN_SUCCESSFUL");
  }

  private showScanArticleNotFoundToast() {
    this.dialogService.info("ERROR_SCANNED_ARTICLE_NOT_FOUND");
  }

  private showLabelScanError() {
    this.dialogService.notify("LABEL_SCAN_ERROR");
  }

  private loadScannedArticleDetails(articleLabel: ArticleLabel) {
    this.loading.start();
    this.detailsSubscription && this.detailsSubscription.unsubscribe();
    this.detailsSubscription = this.sdsService
      .loadScannedArticleDetails(articleLabel)
      .subscribe(
        (scannedArticleDetails) => {
          this.loading.stop();
          if (scannedArticleDetails) {
            this.navigateToInfoCategory(
              scannedArticleDetails.article,
              scannedArticleDetails.sds
            );
          } else {
            this.showScanArticleNotFoundToast();
            this.searchTerm = articleLabel.materialNumber;
            this.searchByArticleLabel(articleLabel);
          }
        },
        (error) => {
          this.loading.stop();
          this.showScanArticleNotFoundToast();
          this.searchTerm = articleLabel.materialNumber;
          this.searchByArticleLabel(articleLabel);
        }
      );
  }

  ionViewDidEnter() {
    if (Capacitor.getPlatform() == "web") {
      this.storage.get(storageKeys.slideVisited).then((value) => {
        if (value) {
          this.storage.get(storageKeys.cookieAccepted).then((value) => {
            if (!value) {
              setTimeout(() => {
                this.settingsService.showCookieNotification();
              }, 1000);
            }
          });
        }
      });
    }
  }

  onBackClick() {
    //  this.navCtrl.pop();
    this.router.navigateByUrl("/landingpage", { replaceUrl: true });
  }

  onClickTitle() {
    this.router.navigateByUrl("/landingpage", { replaceUrl: true });

    if(this.searchbar.value.length > 0){
      this.sdsService.search$.next("");
      this.searchbar.value = null;
      this.isNoSearchText = true;
    }
  }

  onFavClick() {
    this.isNoSearchText = false;
    this.router.navigateByUrl("/favoriteLists", { replaceUrl: true });
  }

  onHistoryClick() {
    this.isNoSearchText = false;
    this.router.navigateByUrl("/history", { replaceUrl: true });
  }
  historyBack(e){
    e.stopPropagation();
    const historyBackOverride = (window as any).historyBackOverride;
    if (historyBackOverride) {
      historyBackOverride();
      (window as any).historyBackOverride = null;
    } else {
      this.location.back();
    }
  }

  onSettingsClick() {
    this.isNoSearchText = false;
    this.router.navigateByUrl("/settings", { replaceUrl: true });
  }

  onClickLogin() {
    // this.loginUserName = localStorage.getItem("loginUser")
    this.isNoSearchText = false;
    this.router.navigateByUrl("/login", { replaceUrl: true });
  }

  async showLogout(){
    this.onLogoutClicked = false;
     if(this.loginUserName != '' || this.loginUserName != undefined){
      this.logoutpopover = await this.popoverController.create({
        component: LogoutPopoverComponent,
        cssClass: "logout-popover",
        componentProps: {
          loginuser: this.loginUserName,
          onLogout: () => {
            this.logoutpopover.dismiss();
          },
        },
      });
      await this.logoutpopover.present();
    }
    // else{
    //   await this.logoutpopover.dismiss();
    // }

    if(this.logoutpopover.dismiss){
      this.onLogoutClicked = true;
    }

  }

  ionViewWillUnload(): void {
    // this.connectivitySubscription && this.connectivitySubscription.unsubscribe();
    this.settingsSubscription && this.settingsSubscription.unsubscribe();
  }

  // e: { target: { value: any; }; detail: { value: string; }; }
  public searchArticles(val: string) {
    this.isNoSearchText = false;
    this.searchTerm = val;

    if (this.searchTerm && this.searchTerm.length > 2) {
      this.prepareSearch();
      this.searchSubscription && this.searchSubscription.unsubscribe();
      this.searchSubscription = this.performSearchArticles(0)
        .pipe(
          tap((result) => this.handleSearchResult(result)),
          map((result) => result.results)
        )
        .subscribe(
          (results) => {
            return (this.searchResults = results.filter((item) => {
              if (item.articleNumber) {
                item.articleNumber = item.articleNumber.replace("###", " ");
              }
              return this.filterOut_Bulk_Var_0000_9999(item);
            }));
          },
          (err) => this.onSearchError(err)
        );
    } else {
      this.resetSearchResults();
    }
  }

  private resetSearchResults() {
    this.loading.stop();
    this.isNoSearchText = true;
    this.searchResults = null;
    this.searchHits = -1;
  }

  private resetSearchTerm() {
    this.searchTerm = "";
  }

  private onSearchError(error = null) {
    this.loading.stop();
    this.searchResults = null;
    this.searchHits = 0;
    this.searchTerm = "";
    this.dialogService.critical("UNEXPECTED_ERROR", "ERROR_MESSAGE_APP");
  }

  private performSearchArticles(offset: number): Observable<SearchResult> {
    this.searchOffset = offset;
    return this.sdsService.searchArticleByMaterialNumberAndLimit(
      null,
      this.searchTerm,
      this.searchLimit,
      this.searchOffset
    );
  }

  private prepareSearch() {
    this.loading.start();
    this.searchResults = null;
    this.searchHits = -1;
    this.help = false;
  }

  private searchByArticleLabel(articleLabel: ArticleLabel) {
    this.prepareSearch();
    this.searchSubscription && this.searchSubscription.unsubscribe();
    this.searchSubscription = this.sdsService
      .searchArticleByLabelAndLimit(
        articleLabel,
        this.searchLimit,
        this.searchOffset
      )
      .pipe(
        tap((result) => {
          this.loading.stop();
          this.handleSearchResult(result);
        }),
        map((result) => result.results)
      )
      .subscribe(
        (results) => {
          return (this.searchResults = results.filter((item) => {
            if (item.articleNumber) {
              item.articleNumber = item.articleNumber.replace("###", " ");
            }
            return this.filterOut_Bulk_Var_0000_9999(item);
          }));
        },
        () => this.resetSearchResults()
      );
  }

  private handleSearchResult(searchResult: SearchResult) {
    this.loading.stop();
    if (searchResult) {
      this.searchHits = searchResult.hits ? searchResult.hits : 0;
      if (searchResult.results && searchResult.results.length > 0) {
        this.hideHelp();
        if (searchResult.hits > this.searchLimit) {
          // this.showToManyResultsToast();
        } else if (searchResult.hits < this.searchLimit) {
          this.searchOffset = -1;
        }
      }
    }
  }

  private hideHelp() {
    this.help = false;
  }

  private filterOut_Bulk_Var_0000_9999(item: Article): boolean {
    for (let filterItem of this.filterOutIncludes) {
      if (item.materialNumber.toLocaleLowerCase().includes(filterItem)) {
        return false;
      }
    }
    for (let filterItem of this.filterOutEnd) {
      if (item.materialNumber.toLocaleLowerCase().endsWith(filterItem)) {
        return false;
      }
    }
    return item.system.toLowerCase() != this.filterOut;
  }

  private showHelp() {
    this.help = true;
  }

  public showArticle(selectedArticle: Article) {
    this.loading.start();
    this.detailsSubscription && this.detailsSubscription.unsubscribe();
    this.detailsSubscription = this.sdsService
      .loadCurrentArticleDetails(selectedArticle)
      .subscribe(
        (sds) => {
          this.checkSDSPresent(selectedArticle, sds);
        },
        (error) => {
          this.showArticleDetailsError();
        }
      );
  }

  private checkSDSPresent(selectedArticle: Article, sds: SafetyDataSheet) {
    this.loading.stop();
    if (sds) {
      this.navigateToInfoCategory(selectedArticle, sds);
    } else {
      this.dialogService.critical("ERROR_TITLE_SDS", "ERROR_MESSAGE_SDS");
    }
  }

  private showArticleDetailsError() {
    this.loading.stop();
    this.dialogService.notify("ERROR_MESSAGE_SDS");
  }

  private navigateToInfoCategory(
    selectedArticle: Article,
    sds: SafetyDataSheet
  ) {
    let extras: NavigationExtras = {
      state: {
        selectedArticle: { ...selectedArticle, offlineAvailable: false },
        sds: sds,
      },
    };
    this.navigateToNavigationExtras("infocategory", extras);
  }

  private navigateToNavigationExtras(page: string, extras: NavigationExtras) {
    if (this.disabledForWhile) {
      return;
    }
    this.disabledForWhile = true;
    setTimeout(() => {
      this.disabledForWhile = false;
    }, 1000);
    this.router.navigate([page], extras);
  }

  async onAppLanguageChange(selectedLanguage: string) {
    this.settings.appLanguage = selectedLanguage;
    this.storage.set(storageKeys.fallbackLang, selectedLanguage);
    await this.updateSettings();
    if (Capacitor.getPlatform() == "web") {
      //  this.navCtrl.navigateRoot("/search", {replaceUrl: true});
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      this.dialogService.appRestartToast("LANGUAGE_CHANGE");
    }
  }

  async updateSettings() {
    try {
      await this.settingsService.updateSettings(this.settings);
    } catch (err) {
      if (!this.connectivity.checkNetworkConnectivity()) {
        this.dialogService.notify("INTERNET_DISCONNECTED");
      } else {
        this.dialogService.notify("ERROR_MESSAGE_APP");
      }
    }
  }

  // Web Footer Functions

  public navigateToPlayStore() {
    if (environment.region == "ROW") {
      this.external.openExternalUrl(
        "https://play.google.com/store/apps/details?id=de.merck.merckproductsafety.googleplay"
      );
    } else {
      this.external.openExternalUrl(
        "https://play.google.com/store/apps/details?id=com.emd.mymsafety.googleplay"
      );
    }
  }

  public navigateToAppleStore() {
    let country = environment.validityArea.toLowerCase();
    let settingUnsubscribe = this.settingsService.settings.subscribe(
      (settingInfo) => {
        country = settingInfo.validityAreaLanguage.validityArea.toLowerCase();
      }
    );
    let url =
      "https://apps.apple.com/us/app/my-m-safety-sds-label/id1442236510";
    if (environment.region == "ROW") {
      url = `https://apps.apple.com/${country}/app/my-m-safety-sds-label/id1440030082`;
    }
    settingUnsubscribe.unsubscribe();
    this.external.openExternalUrl(url);
  }

  dataPrivacyClick() {
    this.isNoSearchText = false;
    this.router.navigateByUrl("/impressum", { replaceUrl: true });
  }

  userGuideClick() {
    this.isNoSearchText = false;
    this.router.navigateByUrl("/userguide", { replaceUrl: true });
  }

  custServicesClick() {
    this.isNoSearchText = false;
    this.router.navigateByUrl("/contact", { replaceUrl: true });
  }

  async changeLangOnChinaWebPortal(selectedLanguage) {
    this.settings.appLanguage = selectedLanguage;
    this.storage.set(storageKeys.fallbackLang, selectedLanguage);
    // this.dialogService.appRestartToast('LANGUAGE_CHANGE');
    await this.updateSettings();
    if (Capacitor.getPlatform() == "web") {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      this.dialogService.appRestartToast("LANGUAGE_CHANGE");
    }
  }
}
