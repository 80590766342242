import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Capacitor} from '@capacitor/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landingpage',
    pathMatch: 'full'
  },
  {
    path: 'landingpage',
    loadChildren: () => import('./landingpage/landingpage.module').then( m => m.LandingPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchModule),
    // children: [
    //   {
    //     path: '',
    //      children: [{ path: '', 
    //      loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
    //     }]
    //   },
    //   {
    //     path: 'favoriteLists',
    //     children: [{ path: '', 
    //     loadChildren: () => import('./favorites-lists/favorites-lists.module').then( m => m.FavoritesListsModule),
    //   }]
    //   },
    //   {
    //     path: 'search',
    //      children: [{ path: '', 
    //      loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    //      data: {
    //       autofocus: true
    //     }
    //     }]
    //   },
    //   {
    //     path: 'history',
    //     children: [{ path: '', loadChildren: () => import('./history/history.module').then( m => m.HistoryModule)}]
    //   },
    //   {
    //     path: 'moreOption',
    //     children: [{ path: '', loadChildren: () => import('./more-options/more-options.module').then( m => m.MoreOptionsModule)}]
    //   },
    // ],
  },
  
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginModule)
  },
  {
    path: 'articleDocuments',
    loadChildren: () => import('./article-documents/article-documents.module').then( m => m.ArticleDocumentsModule)
  },
  {
    path: 'articleDetailsOverview',
    loadChildren: () => import('./articledetailsoverview/articledetailsoverview.module').then( m => m.ArticledetailsoverviewModule)
  },
  {
    path: 'articleGenerate',
    loadChildren: () => import('./articlegenerate/articlegenerate.module').then( m => m.ArticlegenerateModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactModule)
  },
  {
    path: 'emergency',
    loadChildren: () => import('./emergency/emergency.module').then( m => m.EmergencyModule)
  },
  {
    path: 'favoriteLists',
    loadChildren: () => import('./favorites-lists/favorites-lists.module').then( m => m.FavoritesListsModule)
  },
  {
    path: 'favoriteLists-achived',
    loadChildren: () => import('./favorites-lists/favorites-lists.module').then( m => m.FavoritesListsModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then( m => m.ImpressumModule)
  },
  {
    path: 'infocategory',
    loadChildren: () => import('./infocategory/infocategory.module').then( m => m.InfocategoryModule)
  },
  {
    path: 'legalDisclaimer',
    loadChildren: () => import('./legal-disclaimer/legal-disclaimer.module').then( m => m.LegalDisclaimerModule)
  },
  {
    path: 'passwordReset',
    loadChildren: () => import('./passwordreset/passwordreset.module').then( m => m.PasswordresetModule)
  },
  {
    path: 'sectionTwo',
    loadChildren: () => import('./section-two/section-two.module').then( m => m.SectionTwoModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupModule)
  },
  {
    path: 'tagGenerator',
    loadChildren: () => import('./tagsgenerator/tagsgenerator.module').then( m => m.TagsGeneratorModule)
  },
  {
    path: 'updatetag',
    loadChildren: () => import('./updatetag/updatetag.module').then( m => m.UpdateTagModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'moreOptions',
    loadChildren: () => import('./more-options/more-options.module').then( m => m.MoreOptionsModule)
  },
  {
    path: 'userguide',
    loadChildren: () => import('./userguide/userguide.module').then( m => m.UserGuidePageModule)
  },
  {
    path: 'createaddlist',
    loadChildren: () => import('./create-addlist/create-addlist.module').then( m => m.CreateAddListModule)
  },
];

if (Capacitor.getPlatform() != 'web') {
  routes.shift();
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
  