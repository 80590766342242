import { StorageMigration } from './storage-migration';
import { Storage } from '@ionic/storage';
import { storageKeys } from '../storage-keys';
import { ISettings, Settings, Article, DefaultFavoriteList, FavoritesList } from '../../types/types';

export class Migration1To2 extends StorageMigration {
  constructor() {
    super(1, 2);
  }

  async internalMigrate(storage: Storage) {
    await this.migrateSections(storage);
    await this.migrateFavorites(storage);
  }

  async migrateSections(storage: Storage) {
    const newSectionsIndexes = [5, 6, 8, 10, 11, 12]
    let settings = await this.getSettings(storage);
    if (settings) {
      settings.home = settings.home;
      settings.dataPrivacy = settings.dataPrivacy;
      settings.location = settings.location;
      settings.articleDetails = settings.articleDetails;
      settings.validityAreaLanguage = settings.validityAreaLanguage;
      settings.sections = settings.sections.map((section, index) => {
        return section || newSectionsIndexes.indexOf(index) != -1;
      });
    } else {
      settings = new Settings();
    }

    await this.updateSettings(storage, settings);
  }

  async migrateFavorites(storage: Storage) {
    const favorites = await this.getFavorites(storage);
    const favoritesLists = await this.getFavoritesLists(storage);
    if (!favoritesLists) {
      const defaultList = new DefaultFavoriteList();
      if (favorites) defaultList.articles = favorites;
      const newFavoritesLists = [defaultList];
      this.updateFavorites(storage, newFavoritesLists);
    }
  }

  private updateSettings(storage: Storage, settings: ISettings): Promise<any> {
    return storage.set(storageKeys.settings, settings);
  }

  private getSettings(storage: Storage): Promise<ISettings> {
    return storage.get(storageKeys.settings);
  }

  private updateFavorites(storage: Storage, favoritesLists: FavoritesList[]): Promise<any> {
    storage.set(storageKeys.favoritesLists, favoritesLists);
    return storage.remove('favourites');
  }

  private getFavorites(storage: Storage): Promise<Article[]> {
    return storage.get('favourites');
  }

  private getFavoritesLists(storage: Storage): Promise<Article[]> {
    return storage.get(storageKeys.favoritesLists);
  }
}
