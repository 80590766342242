import { Component, Input } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Html5Qrcode } from "html5-qrcode";
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: "web-scanner",
  templateUrl: "web-scanner.html",
  styleUrls: ['./web-scanner.scss'],
})
export class WebScanner {

  @Input() onScanText: (decodedText: string) => void;
  public cameraId;
  public isMultipleCam: boolean;
  public isFrontCam: boolean;
  private html5QrCode: Html5Qrcode;
  public isDesktop: boolean;
  constructor(
    private deviceService: DeviceDetectorService,
    private modalCtrl: ModalController,
    public translate: TranslateService,
  ) {
    this.isDesktop = this.deviceService.deviceType !== 'tablet' && (this.deviceService.os === 'Mac' || this.deviceService.os === 'Windows');
  }

  async ngOnInit() {
    this.onScannerLoad();
  }

  async onCameraSwitch(){
    this.isFrontCam = !this.isFrontCam    
    await this.html5QrCode.stop();
    this.onScannerLoad();
  }

  async onScannerLoad(){ 
    const devices = await Html5Qrcode.getCameras();

    if (devices && devices.length) {
      if(devices.length == 1){ 
        this.isMultipleCam = false;       
        this.cameraId = devices[0].id;
      }else{
        this.isMultipleCam = true;
        if (this.isFrontCam) {
          this.cameraId = devices[0].id; //Front Camera
        } else {
          this.cameraId = devices[devices.length - 1].id; //Back Camera
        }
      } 
    }
    this.html5QrCode = new Html5Qrcode("reader");
    await this.html5QrCode.start(this.cameraId, { fps: 10, qrbox: 250 }, decodedText => {
        if (decodedText) {
          this.onScanText(decodedText);
          this.onStopScanning();
        }
    }, _e => {});  
  }

  onStopScanning() {
    this.html5QrCode.stop();
    this.modalCtrl.dismiss();
  }
}
